export enum DataId {
  CarFinderFilter = 'car-finder-filter',
  CarFinderFilterClose = 'car-finder-filter-close',
  CarFinderFilterShowResultsMobile = 'car-finder-filter-show-results-mobile',
  CarFinderResult = 'car-finder-result',
  CarFinderPagination = 'car-finder-pagination',
  CookiePolicy = 'cookie-policy',
  TermsOfUse = 'terms-of-use',
  PrivacyPolicy = 'privacy-policy',
  CaliforniaPrivacyPolicy = 'ca-privacy-policy',
  ReturnToHome = 'return-to-home',
  Reload = 'reload',
  EmbedElement = 'embed-element',
  PhotoGallerySeeAllButton = 'photo-gallery-see-all-button',
  PhotoGalleryPreviewCard = 'photo-gallery-preview-card',
  NotFoundPage = 'not-found-page',
  GenericErrorPage = 'generic-error-page',
  ArticleBody = 'article-body',
  ArticleBodyListElement = 'article-body-list-element',
  ArticleBodyTableElement = 'article-body-table-element',
  ArticleBodyTextElement = 'article-body-text-element',
  ArticleByline = 'article-byline',
  ArticleContributorLink = 'article-contributor-link',
  ArticleGrid = 'article-grid',
  ArticleGridCard = 'article-grid-card',
  TextInsertLink = 'text-insert-link',
  BrightEdgeLink = 'bright-edge-link',
  BrightEdgeSection = 'bright-edge-section',
  CarMatchBanner = 'car-match-banner',
  CarMatchBannerLink = 'car-match-banner-link',
  CollapsibleComment = 'collapsible-comment',
  Comment = 'comment',
  Commenting = 'commenting',
  DiscoveryHero = 'discovery-hero-bg-homepage',
  DiscoveryHeroLink = 'discovery-hero-link',
  SignupToComment = 'signup-to-comment',
  AddCommentButton = 'add-comment-button',
  CancelReplyButton = 'cancel-reply-button',
  ReplyToComment = 'reply-to-comment',
  ShowReplies = 'show-replies',
  CommentingGuidelines = 'commenting-guidelines',
  ShowMoreComments = 'show-more-comments',
  CommentMenuIconButton = 'comment-menu-icon-button',
  CommentMenuModal = 'comment-menu-modal',
  CloseCommentMenuModalIconButton = 'close-comment-menu-icon-button',
  GalleryBackButton = 'gallery-back-button',
  Gallery = 'gallery',
  Breadcrumb = 'bread-crumb',
  FluidAdPaginationButton = 'fluid-ad-pagination-button',
  FluidAd = 'fluid-ad',
  RelatedArticlesCard = 'related-articles-card',
  RelatedArticles = 'related-articles',
  CertifiedRelatedArticlesCard = 'certified-related-articles-card',
  CertifiedRealatedArticles = 'certified-related-articles',
  TrendingArticlesLink = 'trending-articles-link',
  LegalNoticesLink = 'legal-notices-link',
  TrendingWidget = 'trending-widget',
  VideoDetailPage = 'video-detail-page',
  VideoMuteButton = 'video-mute-button',
  VideoUnmuteButton = 'video-unmute-button',
  VideoVolumeSliderMuteButton = 'video-volume-slider-mute-button',
  VideoVolumeSliderUnmuteButton = 'video-voume-slider-unmute-button',
  VideoPlayer = 'video-player',
  VideoPlaylist = 'video-playlist',
  VideoPlaylistCard = 'video-playlist-card',
  VideoProgressBar = 'video-progress-bar',
  VideoToggleFullScreenButton = 'video-toggle-full-screen-button',
  VideoPlayButton = 'video-play-button',
  VideoPauseButton = 'video-pause-button',
  VideoOverlayTogglePlayButton = 'video-overlay-toggle-play-button',
  NextVideoButton = 'next-video-button',
  CloseVideoPlayerIconButton = 'close-video-player-icon-button',
  CommentAlertButton = 'comment-alert-button',
  DeleteCommentConfirmButton = 'delete-comment-confirm-button',
  DeleteCommentCancelButton = 'delete-comment-cancel-button',
  ArticleStripCard = 'article-strip-card',
  ArticleStrip = 'article-strip',
  HeroCard = 'hero-card',
  Hero3Up = 'hero-3-up',
  FilmStripCard = 'film-strip-card',
  FilmStrip = 'film-strip',
  RiverCard = 'river-card',
  River = 'river',
  CloseTopAdIconButton = 'close-top-ad-icon-button',
  TopAd = 'top-ad',
  SocialShareFacebookIconButton = 'social-share-facebook-icon-button',
  SocialShareXIconButton = 'social-share-x-icon-button',
  CopyArticleToClipboardButton = 'copy-article-to-clipboard-button',
  UltimateCar = 'ultimate-car',
  UltimateCarCard = 'ultimate-car-card',
  BottomAdhesionAd = 'bottom-adhesion',
  CloseBottomAdButton = 'close-bottom-ad-button',
  CarMatchBannerPackage = 'car-match-banner-package',
  CarMatchBannerModule = 'car-match-banner-module',
  Banner = 'banner',
  EventCalendar = 'event-calendar',
  EventCalendarCard = 'event-calendar-card',
  EventSponsorship = 'event-sponsorship',
  EventSponsorshipCard = 'event-sponsorship-card',
  DtcWidget = 'dtc-widget',
  DtcWidgetCard = 'dtc-widget-card',
  DtcWidgetHeaderLogo = 'dtc-widget-header-logo',
  DtcWidgetListings = 'dtc-widget-listings',
  DtcWidgetMarketplaceBanner = 'dtc-widget-marketplace-banner',
  ScrollToComment = 'scroll-to-comment',
  SellYourVehicleLink = 'sell-your-vehicle-link',
  SellYourVehicle = 'sell-your-vehicle',
  SponsorshipAdLink = 'sponsorship-ad-link',
  MmyAuthor = 'mmy-author',
  MmyPhotoGalleryBtn = 'mmy-photo-gallery-btn',
  RelatedModels = 'related-models',
  MakeBody = 'make-body',
  MmyBody = 'mmy-body',
  MmySpecBtn = 'mmy-spec-btn',
  MMRankingModalListing = 'mm-ranking-modal-listing',
  MMRankingModalCarIcon = 'mm-ranking-modal-caricon',
  MMRankingModalHeader = 'mm-ranking-modal-header',
  MMRankingSubclassText = 'mm-ranking-subclass-text',
  MMRankingNameDropdown = 'mm-ranking-name-dropdown',
  MMRanking = 'mm-ranking',
  MMTopCompetitorsFilter = 'mm-top-competitors-filter',
  PricingTrimsModalCloseIcon = 'pricing-trims-modal-close-icon',
  PricingTrims = 'pricing-trims',
  Specs = 'specs-page',
  SpecsBackButton = 'specs-back-button',
  CertifiedHero = 'certified-hero',
  CertifiedHeroGoBtn = 'certified-header-go-btn',
  StreamingPlayer = 'streaming-player',
  FastTvFAQLink = 'fast-tv-faq-link',
  FastTvProviderLink = 'fast-tv-provider-link',
  FastTv = 'fast-tv',
  BGMakesCard = 'bg-makes-card',
  BGMakes = 'bg-makes',
  BGMakesLoadMore = 'bg-makes-load-more',
  BGVehicleGridItem = 'bg-vehicle-grid-item',
  SearchByPrice = 'search-by-price',
  SearchByPriceCard = 'search-by-price-card',
  MMReviewer = 'mm-reviewer',
  MMHistoryReviewer = 'mm-history-reviewer',
  ShowMoreMakeFAQ = 'show-more-make-faq',
  ShowLessMakeFAQ = 'show-less-make-faq',
  ShowMoreMake = 'show-more-make',
  ShowMoreHistory = 'show-more-history',
  ShowLessHistory = 'show-less-history',
  ShowMoreYMM = 'show-more-ymm',
  TypeAheadSearchMagnifierBtn = 'type-ahead-search-magnifier-btn',
  BGTypeAheadSearch = 'bg-type-ahead-search',
  AllModelYearsBtn = 'mm-all-model-years-btn',
  AllModelYears = 'mm-all-model-years',
  MmyFooterScoreBtn = 'mmy-footer-score-btn',
  MmyScoreBtn = 'mmy-score-btn',
  MmyPhotoGallery = 'mmy-photo-gallery',
  MmyLocalListingBtn = 'mmy-local-listing-btn',
  FindYourVehicleDialogTrigger = 'find-your-vehicle-dialog-trigger',
  FindYourVehicleDialogFooterButton = 'find-your-vehicle-dialog-footer-button',
  FindYourVehicleDialog = 'find-your-vehicle-dialog',
  BuyersGuideVehiclePageHeader = 'buyers-guide-vehicle-page-header',
  BuyersGuideHomepage = 'buyers-guide-homepage',
  BuyersGuideQuickYearLinks = 'quick-year-links',
  AutoSuggest = 'autosuggest',
  OtherModelsBtn = 'other-models-button',
  OtherModels = 'other-models',
  LoadMoreOtherModels = 'load-more-other-models',
  BGHeroLearnMoreBtn = 'bg-hero-learn-more-btn',
  BuyersGuideHero = 'buyers-guide-hero',
  BGHeroRankCarsBtn = 'bg-hero-rank-cars-btn',
  BGHeroRankCars = 'bg-hero-rank-cars',
  BGHeroReadMore = 'bg-hero-read-more',
  BGHeroReadMoreBtn = 'bg-hero-read-more-btn',
  BGHeroExplore = 'bg-hero-explore',
  BGHeroExploreBtn = 'bg-hero-explore-btn',
  MakeVehiclesShowMore = 'make-vehicles-show-more',
  BGMakeVehicleType = 'bg-make-vehicles-type',
  RankingMakeModelsVehicle = 'ranking-make-model-vehicles',
  makeModelVehicles = 'make-models',
  loadMoreMakeModelVehicles = 'load-more-ranking-make-models',
  ShowMoreRankingSeoContent = 'show-more-rankingseocontent',
  ShowLessRankingSeoContent = 'show-less-rankingseocontent',
  ShowMoreBgSeoContent = 'show-more-bgseocontent',
  LoadMoreRankingsSeo = 'load-more-rankings-seo',
  SearchByTypeRankings = 'search-by-price-rankings',
  MakeBodyStyleHeading = 'make-bodystyle-heading',
  MMYHeroImage = 'mmy-hero-image',
  TrendingWidgetStripCard = 'trending-widget-strip-card',
  ContributorPageHeroIcon = 'contributor-page-hero-icon',
  LoadMoreButton = 'load-more-button',
  StaffPageContributorWidgetSocialLink = 'staff-page-contributor-widget-social-link',
  StaffPageContributorWidget = 'staff-page-contributor-widget',
  Staff = 'staff',
  ArticlePageContributorWidget = 'article-page-contributor-widget',
  ArticlePageContributorWidgetReadMore = 'article-page-contributor-widget-read-more',
  ArticlePageContributorWidgetAuthorName = 'article-page-contributor-widget-author-name',
  ArticlePageContributorWidgetSocialLink = 'article-page-contributor-widget-social-link',
  YouMayAlsoLikeRecommendation = 'you-may-also-like-recommendation',
  AffiliateMarketingCarousel = 'affiliate-marketing-carousel',
  AffiliateMarketingCarouselCard = 'affiliate-marketing-carousel-card',
  BuyNowAffiliateMarketingButton = 'buy-now-affiliate-marketing-button',
  MagazineBrandPage = 'magazine-brand-page',
  MagazineBrandCarousel = 'magazine-brand-carousel',
  MagazineBrandCarouselCard = 'magazine-brand-carousel-card',
  Magazines = 'magazines',
  MagazineSignUpButton = 'magazine-sign-up-button',
  MagazineCarouselCard = 'magazine-carousel-card',
  MagazineHomePage = 'magazine-home-page',
  MagazineHeaderLink = 'magazine-header-link',
  Newsletters = 'newsletters',
  NewslettersAddMotorTrend = 'newsletters-add-motortrend',
  NewslettersAddHotRod = 'newsletters-add-hot-rod',
  NewslettersSignUp = 'newsletters-sign-up',
  NewslettersTermsOfUse = 'newsletters-terms-of-use',
  NewslettersPrivacyPolicy = 'newsletters-privacy-policy',
  NewslettersMotorTrendAndAffiliates = 'newsletters-motortrend-and-affiliates',
  NewslettersDialogPrivacyPolicy = 'newsletters-dialog-privacy-policy',
  VehiclePhotosPage = 'vehicle-photos-page',
  VehiclePhotosPageBackButton = 'vehicle-photos-page-back-button',
  ArticlePhotosPage = 'article-photos-page',
  ArticlePhotosPageBackButton = 'article-photos-page-back-button',
  CertifiedBodyStyleCard = 'certified-body-style-card',
  CertifiedPageBodyStyle = 'certified-page-body-style',
  OttoHeader = 'otto-header',
  OttoCloseIconButton = 'otto-close-icon-button',
  OttoArticles = 'otto-articles',
  OttoVehicles = 'otto-vehicles',
  GlobalHeader = 'global-header',
  GlobalHeaderActionButton = 'action-button',
  OttoSendMessageButton = 'otto-send-message-button',
  OttoForm = 'otto-form',
  OttoFeedback = 'otto-feedback',
}

export enum ViewName {
  Article = 'Article',
  CarFinder = 'Car Finder',
  Homepage = 'Home',
  IndexPage = 'Index Page',
  ListArticle = 'List Article',
  MakemodelYear = 'MakeModelYear',
  FastTv = 'FAST TV',
  MagazineBrandYear = 'Magazine Brand : Year Collection',
  MagazineIndex = 'Digital Magazines Index',
  MagazineDetail = 'Magazine Issue',
  PhotoGallery = 'Photo Gallery',
}

export enum ViewGroup {
  Awards = 'Awards',
  Certified = 'Certified',
  Magazines = 'Digital Magazines',
  Events = 'Events',
  Features = 'Features',
  How_to = 'How-to',
  Landing = 'Landing',
  News = 'News',
  Reviews = 'Reviews',
  Stories = 'Stories',
  CuratedPages = 'Curated Pages',
  Error = 'Error',
  Watch = 'Watch',
  NoTaxonomy = 'no_taxonomy',
  BuyersGuide = 'Buyers Guide',
  Newsletters = 'Newsletters',
  Otto = 'Otto',
}

export enum NitrousExperiments {
  gptBeforeInteractive = 'gptBeforeInteractive',
}
